/* ==========================================================================
   #SIDE NAVIGATION
   ========================================================================== */

.cca-side-navigation {
  @include govuk-font(16);

  @include govuk-media-query($until: tablet) {
    display: flex;
    overflow-x: scroll;
  }

  @include govuk-media-query($from: tablet) {
    display: block;
    padding: govuk-spacing(4) 0 0;
  }
}

.cca-side-navigation__title {
  @include govuk-font($size: 19);
  color: govuk-colour('dark-grey');
  font-weight: normal;
  margin: 0;
  padding: govuk-spacing(2);
  padding-left: govuk-spacing(2) + 4px;

  @include govuk-media-query($until: tablet) {
    display: none;
  }
}

.cca-side-navigation__list {
  list-style: none;
  margin: 0;
  padding: 0;

  @include govuk-media-query($until: tablet) {
    display: flex;
    margin: 0;
    white-space: nowrap;
  }

  @include govuk-media-query($from: tablet) {
    margin-bottom: govuk-spacing(4);
  }
}

.cca-side-navigation__item {
  @include govuk-media-query($until: tablet) {
    display: flex;
  }

  a,
  a:link,
  a:visited {
    background-color: inherit;
    color: $govuk-link-colour;
    display: block;
    text-decoration: none;

    @include govuk-media-query($until: tablet) {
      border-bottom: 4px solid transparent;
      padding: govuk-spacing(3);
      padding-bottom: govuk-spacing(3) - 4px; // Compensate for 4px border
    }

    @include govuk-media-query($from: tablet) {
      background-color: inherit;
      border-left: 4px solid transparent;
      padding: govuk-spacing(2);
    }
  }

  a:hover {
    color: $govuk-link-hover-colour;
  }

  a:focus {
    color: $govuk-focus-text-colour;
    background-color: $govuk-focus-colour;
    border-color: $govuk-focus-text-colour;
    position: relative;
  }
}

.cca-side-navigation__item--active {
  a:link,
  a:visited {
    border-color: $govuk-link-colour;
    color: $govuk-link-colour;
    font-weight: bold;
  }

  a:hover {
    color: $govuk-link-hover-colour;
    border-color: $govuk-link-hover-colour;
  }

  a:focus {
    color: $govuk-focus-text-colour;
    background-color: $govuk-focus-colour;
    border-color: $govuk-focus-text-colour;
  }

  @include govuk-media-query($from: tablet) {
    a:link,
    a:visited {
      background-color: govuk-colour('light-grey');
    }

    a:focus {
      color: $govuk-focus-text-colour;
      background-color: $govuk-focus-colour;
    }
  }
}
