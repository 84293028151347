@use 'base' as govuk;

.cca-add-another {
  &__item {
    margin: 0;
    margin-top: govuk.govuk-spacing(6);
    padding: 0;
    position: relative;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__title {
    float: left;
    padding: 4px 0;
    width: 100%;

    & + .govuk-form-group {
      clear: left;
    }
  }

  &__remove-button {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__add-button {
    display: block;
  }
}

.cca-add-another__heading:focus {
  background-color: govuk.$govuk-focus-colour;
  color: govuk.$govuk-focus-text-colour;
  box-shadow:
    0 -2px govuk.$govuk-focus-colour,
    0 4px govuk.$govuk-focus-text-colour;
  outline: none;
}
