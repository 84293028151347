/* ==========================================================================
   #BANNER
   ========================================================================== */

.cca-banner {
  border: 5px solid $govuk-brand-colour;
  color: $govuk-brand-colour;
  font-size: 0; // Removes white space when using inline-block on child element.
  margin-bottom: govuk-spacing(6);
  padding: govuk-spacing(2);
}

.cca-banner__icon {
  fill: currentColor;
  float: left;
  margin-right: govuk-spacing(2);
}

.cca-banner__message {
  @include govuk-font($size: 19);
  color: govuk-colour('black');
  display: block;
  overflow: hidden;
}

.cca-banner__message h2 {
  margin-bottom: govuk-spacing(2);
}

.cca-banner__message h2:last-child,
.cca-banner__message p:last-child {
  margin-bottom: 0;
}

.cca-banner__assistive {
  @include govuk-visually-hidden;
}

/* Style variants
     ========================================================================== */

.cca-banner--success {
  border-color: govuk-colour('green');
  color: govuk-colour('green');
}

.cca-banner--warning {
  border-color: govuk-colour('red');
  color: govuk-colour('red');
}
