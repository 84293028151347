@forward '../app/shared/components/add-another/add-another.directive';

.overflow-auto {
  overflow: auto;

  &.overflow-auto-table {
    padding-left: 1px;
    padding-right: 1px;
  }
}
