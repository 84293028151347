@include govuk-exports("govuk/component/back-link") {
  // Component font-size on the Frontend (used for calculations)
  $font-size: 16;

  // Size of chevron (excluding border)
  $chevron-size: govuk-em(7px, $font-size);

  // Size of chevron border
  $chevron-border-min-width: 1px;
  $chevron-border-width: govuk-em($chevron-border-min-width, $font-size);

  // Colour of chevron
  $chevron-border-colour: $govuk-secondary-text-colour;

  .govuk-back-link {
    @include govuk-font-size($size: $font-size);
    @include govuk-link-common;
    @include govuk-link-style-text;

    display: inline-block;
    position: relative;

    margin-top: govuk-spacing(3);
    margin-bottom: govuk-spacing(3);

    // Allow space for the arrow
    padding-left: govuk-em(14px, $font-size);
  }

  // Prepend left pointing chevron
  .govuk-back-link::before {
    content: "";
    display: block;

    // Vertically align with the parent element
    position: absolute;
    top: 0;
    bottom: 0;
    left: govuk-em(3px, $font-size);

    width: $chevron-size;
    height: $chevron-size;

    margin: auto 0;

    transform: rotate(225deg);

    border: solid;
    border-width: $chevron-border-min-width $chevron-border-min-width 0 0;
    border-color: $chevron-border-colour;

    @supports (border-width: unquote("max(0px)")) {
      $border-width-eval: "max(#{$chevron-border-min-width}, #{$chevron-border-width})";

      // Ensure that the chevron never gets smaller than 16px
      border-width: unquote($border-width-eval) unquote($border-width-eval) 0 0;
      font-size: unquote("max(#{$font-size * 1px}, 1em)");
    }
  }

  .govuk-back-link:focus::before {
    border-color: $govuk-focus-text-colour;
  }

  .govuk-back-link::after {
    content: "";
    position: absolute;
    top: -14px;
    right: 0;
    bottom: -14px;
    left: 0;
  }

  .govuk-back-link--inverse {
    @include govuk-link-style-inverse;

    &::before {
      border-color: currentcolor;
    }
  }
}

/*# sourceMappingURL=_index.scss.map */
