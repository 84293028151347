/**
Helper classes that were not covered through govuk-components
 */

.pre-wrap {
  white-space: pre-wrap;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
  margin-left: -(govuk-spacing(3));
  margin-right: -(govuk-spacing(3));
}

.align-items-end {
  align-items: flex-end;
}

.col-flex-three-quarters {
  box-sizing: border-box;
  flex: 0 0 auto;
  width: govuk-grid-width('three-quarters');
  padding: 0 govuk-spacing(3);
}

.col-flex-one-quarter {
  box-sizing: border-box;
  flex: 0 0 auto;
  width: govuk-grid-width('one-quarter');
  padding: 0 govuk-spacing(3) 0 0;
}

.govuk-tag-heading-align {
  margin-left: 5px;
  display: inline-flex;
  vertical-align: middle;
  line-height: 1.2;
  font-size: 0.875rem;
  margin-top: -6px;
}

a[role='button'] {
  @extend %govuk-link;
  color: $govuk-link-visited-colour;
  cursor: pointer;
}

.highlight-error {
  color: $govuk-error-colour;
  font-weight: bold;
}

.align-self-end {
  align-self: end;
}

.cca-hidden {
  display: none;
}
