@include govuk-exports("govuk/component/label") {
  .govuk-label {
    @include govuk-font($size: 19);
    @include govuk-text-colour;

    display: block;

    margin-bottom: govuk-spacing(1);
  }

  // Modifiers that make labels look more like their equivalent headings
  .govuk-label--xl,
  .govuk-label--l,
  .govuk-label--m {
    @include govuk-typography-weight-bold;
    margin-bottom: govuk-spacing(3);
  }

  .govuk-label--xl {
    @include govuk-font-size($size: 48);
  }

  .govuk-label--l {
    @include govuk-font-size($size: 36);
  }

  .govuk-label--m {
    @include govuk-font-size($size: 24);
  }

  .govuk-label--s {
    @include govuk-typography-weight-bold;
  }

  // When the label is nested inside a heading, override the heading so that it
  // does not have a margin. Effectively we want to be able to treat the heading
  // as if it is not there.
  //
  // This breaks BEM conventions because it exists as a parent of the 'block',
  // so we can't really consider an element.
  .govuk-label-wrapper {
    margin: 0;
  }
}

/*# sourceMappingURL=_index.scss.map */
