////
/// @group settings/layout
////

// =========================================================
// Page layout
// =========================================================

/// Width of main container
///
/// @type Number
/// @access public

$govuk-page-width: 960px !default;

/// Map of grid column widths
///
/// @type Map
/// @access public

$govuk-grid-widths: (
  one-quarter: (
    100% / 4
  ),
  one-third: (
    100% / 3
  ),
  one-half: (
    100% / 2
  ),
  two-thirds: (
    200% / 3
  ),
  three-quarters: (
    300% / 4
  ),
  full: 100%
) !default;

/// Width of gutter between grid columns
///
/// @type Number
/// @access public

$govuk-gutter: 30px !default;

/// Width of half the gutter between grid columns
///
/// @type Number
/// @access public

$govuk-gutter-half: $govuk-gutter / 2;

// =========================================================
// Borders
// =========================================================

/// Standard border width
///
/// @type Number
/// @access public

$govuk-border-width: 5px !default;

/// Wide border width
///
/// @type Number
/// @access public

$govuk-border-width-wide: 10px !default;

/// Narrow border width
///
/// @type Number
/// @access public

$govuk-border-width-narrow: 4px !default;

/// Form control border width
///
/// @type Number
/// @access public

$govuk-border-width-form-element: 2px !default;

/// Form group border width when in error state
///
/// @type Number
/// @access public

$govuk-border-width-form-group-error: $govuk-border-width !default;

/// Border width of focus outline
///
/// @type Number
/// @access public

$govuk-focus-width: 3px !default;

/// Hover width for form controls with a hover state
///
/// @type Number
/// @access public

$govuk-hover-width: 10px !default;

/*# sourceMappingURL=_measurements.scss.map */
